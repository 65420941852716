import ko from "knockout";
import { backendService } from "@/services/api_v2_liste.js";
import { localUserSettings } from "@/helper/localUserSettings.js";

function JobListSingleWidgetViewModel(params) {

	var self = this;
	self.onClose = params.onClose;

	// Aktueller Schritt nur auf Mobilplattform nötig
	self.currentStep = ko.observable(1);

	// Abfrageparameter
	self.queryTypeList = [
		{ value: "USED", text: "gebraucht" },
		{ value: "NEW", text: "neu" }
	];
	self.idTypeList = [
		{ value: "EAN", text: "EAN" },
		{ value: "ASIN", text: "ASIN" },
		{ value: "ISBN", text: "ISBN" }
	];
	self.selectedQueryType = ko.observable();
	self.selectedIdType = ko.observable();
	self.selectedId = ko.observable(params.externalId);

	// Ergebnisparameter
	self.queryResult = ko.observable();
	self.queryResult.id = ko.pureComputed(function () {
		var r = self.queryResult();
		return r?.apdb?.amazonMasterData?.asin;
	});
	self.queryResult.title = ko.pureComputed(function () {
		var r = self.queryResult();
		return r?.apdb?.amazonMasterData?.title;
	});
	self.queryResult.timeStamp = ko.pureComputed(function () {
		var r = self.queryResult();
		return r?.apdb?.amazonLatestMwsOffers?.createdAt;
	});
	self.queryResult.rang = ko.pureComputed(function () {
		var r = self.queryResult();
		return r?.apdb?.amazonSalesRankData?.riSalesrank;
	});
	self.queryResult.avgrang = ko.pureComputed(function () {
		var r = self.queryResult();
		return r?.apdb?.amazonSalesRankData?.riSalesrankAverage;
	});

	self.queryResult.bestand_a = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.amazonStockData?.ctInStock;
	});
	self.queryResult.absatz_30_a = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.amazonSalesHistogram?.ctSales30;
	});
	self.queryResult.reichweite_a = ko.pureComputed(function () {
		var absatz30 = self.queryResult.absatz_30_a();
		var bestand = self.queryResult.bestand_a();
		if (absatz30 == null || bestand == null || bestand == 0)
			return;
		if (absatz30 <= 0)
			return "∞";
		if (bestand <= 0)
			return 0;
		return (bestand / absatz30).toFixed(2);
	});
	self.queryResult.reichweiteGross_a = ko.pureComputed(function () {
		var reichweite = self.queryResult.reichweite_a();
		if (reichweite == null)
			return false;
		if (reichweite == "∞")
			return true;
		var rw = parseFloat(reichweite);
		if (rw != NaN) {
			if (rw > 18.0)
				return true;
		}
		return false;
	});

    self.queryResult.bestand_b = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.buchhandelStockData?.ctInStock;
	});
	self.queryResult.absatz_30_b = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.buchhandelSalesHistogram?.ctSales30;
	});
	self.queryResult.reichweite_b = ko.pureComputed(function () {
		var absatz30 = self.queryResult.absatz_30_b();
		var bestand = self.queryResult.bestand_b();
		if (absatz30 == null || bestand == null || bestand == 0)
			return;
		if (absatz30 <= 0)
			return "∞";
		if (bestand <= 0)
			return 0;
		return (bestand / absatz30).toFixed(2);
	});
	self.queryResult.reichweiteGross_b = ko.pureComputed(function () {
		var reichweite = self.queryResult.reichweite_b();
		if (reichweite == null)
			return false;
		if (reichweite == "∞")
			return true;
		var rw = parseFloat(reichweite);
		if (rw != NaN) {
			if (rw > 18.0)
				return true;
		}
		return false;
	});

    self.queryResult.bestand_c = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.trade123StockData?.ctInStock;
	});
	self.queryResult.absatz_30_c = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.trade123SalesHistogram?.ctSales30;
	});
	self.queryResult.reichweite_c = ko.pureComputed(function () {
		var absatz30 = self.queryResult.absatz_30_c();
		var bestand = self.queryResult.bestand_c();
		if (absatz30 == null || bestand == null || bestand == 0)
			return;
		if (absatz30 <= 0)
			return "∞";
		if (bestand <= 0)
			return 0;
		return (bestand / absatz30).toFixed(2);
	});
	self.queryResult.reichweiteGross_c = ko.pureComputed(function () {
		var reichweite = self.queryResult.reichweite_c();
		if (reichweite == null)
			return false;
		if (reichweite == "∞")
			return true;
		var rw = parseFloat(reichweite);
		if (rw != NaN) {
			if (rw > 18.0)
				return true;
		}
		return false;
	});

    self.queryResult.bestand_d = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.warenweltStockData?.ctInStock;
	});
	self.queryResult.absatz_30_d = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.warenweltSalesHistogram?.ctSales30;
	});
	self.queryResult.reichweite_d = ko.pureComputed(function () {
		var absatz30 = self.queryResult.absatz_30_d();
		var bestand = self.queryResult.bestand_d();
		if (absatz30 == null || bestand == null || bestand == 0)
			return;
		if (absatz30 <= 0)
			return "∞";
		if (bestand <= 0)
			return 0;
		return (bestand / absatz30).toFixed(2);
	});
	self.queryResult.reichweiteGross_d = ko.pureComputed(function () {
		var reichweite = self.queryResult.reichweite_d();
		if (reichweite == null)
			return false;
		if (reichweite == "∞")
			return true;
		var rw = parseFloat(reichweite);
		if (rw != NaN) {
			if (rw > 18.0)
				return true;
		}
		return false;
	});

	self.queryResult.bestand_j = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.cheabooStockData?.ctInStock;
	});
	self.queryResult.absatz_30_j = ko.pureComputed(function () {
		return self.queryResult()?.apdb?.cheabooSalesHistogram?.ctSales30;
	});
	self.queryResult.reichweite_j = ko.pureComputed(function () {
		var absatz30 = self.queryResult.absatz_30_j();
		var bestand = self.queryResult.bestand_j();
		if (absatz30 == null || bestand == null || bestand == 0)
			return;
		if (absatz30 <= 0)
			return "∞";
		if (bestand <= 0)
			return 0;
		return (bestand / absatz30).toFixed(2);
	});
	self.queryResult.reichweiteGross_j = ko.pureComputed(function () {
		var reichweite = self.queryResult.reichweite_j();
		if (reichweite == null)
			return false;
		if (reichweite == "∞")
			return true;
		var rw = parseFloat(reichweite);
		if (rw != NaN) {
			if (rw > 18.0)
				return true;
		}
		return false;
	});
    
	self.queryResult.maxek = ko.pureComputed(function () {
		var r = self.queryResult();
		if (self.selectedQueryType() == "USED") {
			return r?.apdb?.amazonMwsCalculationsUsed?.maxEk;
		}
		if (self.selectedQueryType() == "NEW") {
			return r?.apdb?.amazonMwsCalculationsNew?.maxEk;
		}
	});
    self.queryResult.minPreis = ko.pureComputed(function () {
		var r = self.queryResult();
		if (self.selectedQueryType() == "USED") {
			return r?.apdb?.amazonMwsCalculationsUsed?.minPreis;
		}
		if (self.selectedQueryType() == "NEW") {
			return r?.apdb?.amazonMwsCalculationsNew?.minPreis;
		}
	});
	self.queryResult.queryId = null;

	self.queryResult.vlb_lp = ko.pureComputed(function () {
		var r = self.queryResult();
		return r?.apdb?.vlbPriceData?.de?.amount;
	});
	self.queryResult.vlb_fixed_price = ko.pureComputed(function () {
		var r = self.queryResult();
        var fp = r?.apdb?.vlbPriceData?.de?.fixedprice;
        if (fp == null) return "k.A.";
        return (fp === true) ? 'ja' : 'nein';
	});

	// Abfrage durchführen
	self.canStartQuery = ko.pureComputed(function () {
		var selectedId = self.selectedId();
		if (selectedId == null || selectedId.length <= 0)
			return false;
		return true;
	});
	self.startQuery = function () {

		// Check
		if (self.canStartQuery() == false) return;

		// Fehlerhandler
		var fnErrorCallback = function (error) {
			console.log("Es ist ein Fehler aufgetreten. In der Console steht mehr.");
			console.log(error);
			self.onClose();
		};

		// Save Request ID
		self.merge.addItem(self.selectedId());

		// Prepare Command
		self.currentStep(2);
		var cmd = {
			jobName: 'SingleJob',
			identifierType: self.selectedIdType(),
			queryType: self.selectedQueryType(),
			flagPalettenAuswertung: false,
			flagExcelErzeugen: false,
			inputLines: [{
				id: self.selectedId(),
				ek: 0.00,
				zustand: 2
			}]
		};

		let fnSuccessCallback = function (result) {
			let q = result && result.outputLines && result.outputLines.length > 0 ? result.outputLines[0] : null;
			self.queryResult(q)
			self.queryResult.queryId = self.selectedId();
			self.currentStep(3);
			self.selectedId(null);
		};

		backendService.processJob(cmd, fnErrorCallback, fnSuccessCallback);

	};

	// Zurück
	self.reset = function () {
		self.queryResult(null);
		self.selectedId(null);
		self.currentStep(1);
	};

	// Merge 
	self.merge = {
		joblist: ko.observableArray(),
		canActivate: ko.pureComputed(function () {
			return self.merge.joblist().length > 0;
		}),
		isActive: ko.observable(false),
		activate: function () {

			if (self.merge.canActivate() == false)
				return;

			var cmd = {
				jobName: 'Merge',
				queryType: self.selectedQueryType(),
				identifierType: self.selectedIdType(),
				flagPalettenAuswertung: false,
				flagExcelErzeugen: true,
				sourceColumnCaptions: [],
				inputLines: []
			};

			self.merge.joblist().forEach(function (j) {
				cmd.inputLines.push({
					id: j.id,
					ek: 0.00,
					zustand: 2,
					sourceColumns: []
				});
			});

			backendService.processJobAsync(cmd, console.log, function () {
				self.merge.joblist.removeAll();
				localUserSettings.load();
				localUserSettings.clearSingleJobList();
				localUserSettings.save();
				self.onClose();
			});

		},
		initList: function () {
			localUserSettings.load();
			var currentList = localUserSettings.getSingleJobList();
			if (currentList == null) currentList = [];
			self.merge.joblist.removeAll();
			for (var i = 0; i < currentList.length; i++)
				self.merge.joblist.push(currentList[i]);
		},
		addItem: function (itemId) {
			localUserSettings.load();
			localUserSettings.addSingleJobListItem(itemId);
			localUserSettings.save();
			self.merge.initList();
		}
	};

	// INIT
	self.init = function () {
		self.merge.initList();
		localUserSettings.load();
		var q1 = localUserSettings.getSingleJobDefaultQueryTypeId();
		q1 = (q1 == null) ? self.queryTypeList[0] : q1;
		self.selectedQueryType(q1);
		var q3 = localUserSettings.getSingleJobDefaultIdType();
		q3 = (q3 == null) ? self.idTypeList[0] : q3;
		self.selectedIdType(q3);
	}
	self.init();

	// SAVE
	self.selectedQueryType.subscribe(function (newValue) {
		localUserSettings.load();
		localUserSettings.setSingleJobDefaultQueryTypeId(newValue);
		localUserSettings.save();
	});
	self.selectedIdType.subscribe(function (newValue) {
		localUserSettings.load();
		localUserSettings.setSingleJobDefaultIdType(newValue);
		localUserSettings.save();
	});

	// Aufruf über externe ID ?
	if (params.externalId) {
		self.startQuery();
	}

	// Auf DesktopBrowser AutoSet Focus auf ID Feld
	self.idFieldHasFocus = ko.observable(true);
	self.idFieldHasFocus.subscribe(function (newValue) {
		if (newValue == false) {
			setTimeout(function () {
				self.idFieldHasFocus(true);
			}, 3000);
		}
	});
}

import template from "./job-list-single.html?raw";
import * as style from "./job-list-single.scss";

export default {
	viewModel: JobListSingleWidgetViewModel,
	template,
	style
};